.assignments {
    @apply grid
    grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4
    gap-2 md:gap-4;
}


.filterContainer {
    @apply text-white flex flex-wrap justify-between items-center gap-2
    mb-2 md:mb-4;
}

.filterContainer .title {
    @apply flex flex-nowrap gap-4
    w-full sm:w-auto
}

.headerBts {
    @apply w-full sm:w-auto
}

.filter {
    @apply flex flex-nowrap justify-items-center;
}


.filter .btn {
    @apply cursor-pointer flex template__base h-full items-center p-2 hover:bg-opacity-50 hover:bg-gray-800 transition-all duration-200 ease-in-out;
}

.filter .btn div {
    @apply flex items-center justify-center
}


.filter .btn:first-child {
    @apply rounded-l-md;
}

.filter .btn:last-child {
    @apply rounded-r-md;
}

.icon {
    @apply w-4 h-4
        rounded-full

    hover:scale-105 focus:scale-95 transition-all duration-200 ease-in-out;
}

.iconBig {
    @apply w-5 h-5;
}

.iconActive {
    @apply text-green-600;
}

.empty {
    @apply flex flex-col justify-center items-center
        h-full w-full
        gap-2;
}