.inputBlock {
    @apply text-white font-bold text-sm flex flex-col;
}

.inputWidthFull {
    @apply w-full sm:w-auto;
}

.inputBlock textarea {
    height: 100%;
}

.inputBlockTittle {
    @apply text-white font-bold text-sm px-1.5 w-full text-left;
}

.inputBlockTittleDisabled {
    @apply text-gray-500;
}

.inputBlockTittle small {
    @apply text-xs text-gray-400;
}

.input {
    @apply w-full font-medium text-base px-4 py-2 border-none outline-none rounded-md text-white bg-transparent border-white p-2;
    /*input in glassmorphism style*/
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.input:disabled {
    @apply opacity-50;
}

.input:disabled * .inputBlockTittle {
    @apply text-red-600
}

.inputBlock div {
    @apply px-1.5 text-xs text-[#ff7070] mb-2 text-left;
}

.inputOutLine {
    @apply opacity-80 focus:opacity-100;
    backdrop-filter: none;
}

.statementText {
    @apply text-sm text-red-500 font-medium pl-[5px];
}

.wightFull {
    @apply w-full;
}

.resizeOff {
    resize: none;
}

.inputCheckboxBlock {
    @apply w-full font-medium text-base flex flex-nowrap justify-between items-center cursor-pointer;

    /*@apply w-full font-medium text-base px-4 py-2 border-none outline-none rounded-md text-white bg-transparent border-white p-2;*/
    /*!*input in glassmorphism style*!*/
    background: rgba(255, 255, 255, 0.1);
    /*backdrop-filter: blur(10px);*/
    /*-webkit-backdrop-filter: blur(10px);*/
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);

}


.inputCheckbox {
    @apply hidden;
}

.iconDisabled {
    @apply w-5 h-5 text-red-500 opacity-90;
}

.iconEnabled {
    @apply w-5 h-5 text-green-500 opacity-90;
}

.forView {
    @apply text-white opacity-100;
}

.forView:disabled {
    @apply opacity-100;
}