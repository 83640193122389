.filterContainer {
    @apply text-white flex flex-wrap justify-between items-center gap-2
    mb-2 md:mb-4;
}

.weeks {
    @apply flex flex-wrap gap-2 rounded-md p-1;
}

.weeks div {
    @apply
    rounded-md p-1 cursor-pointer;
}

.npBtn {
    @apply flex flex-wrap gap-2 hover:opacity-50 hover:scale-105;
}

.dayInfo {
    @apply flex flex-wrap gap-2 items-center justify-between;
}

.dayInfo .dayName {
    @apply font-semibold text-lg capitalize
}

.dayInfo .dayDate {
    @apply text-sm;
}

