.content {
    @apply flex flex-col items-center justify-center px-6 py-8 mx-auto min-h-screen lg:py-0 text-gray-100;
}

.headerLink {
    @apply flex items-center mb-6 text-2xl font-semibold;
}

.headerLink img {
    @apply w-10 h-10 mr-2 rounded-full;
}

.modal {
    @apply p-0 w-full rounded-lg shadow border md:mt-0 sm:max-w-lg xl:p-0 border-gray-700;
}

.modalBlock {
    @apply p-6 space-y-4 md:space-y-6 sm:p-8;
}

.modalBlock .modalBlockTitle {
    @apply text-xl font-bold leading-tight tracking-tight md:text-2xl;
}

.form {
    @apply space-y-4 md:space-y-6;
}

.separator {
    @apply relative flex items-center px-3;
}

.separator .line {
    @apply flex-grow border-t border-gray-400;
}

.separator .sepText {
    @apply flex-shrink mx-2 text-gray-400;
}

.oauth {
    @apply grid grid-cols-1 md:grid-cols-2 gap-2;
}

.oauth button {
    @apply text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center;
}

.oauth button svg {
    @apply w-4 h-4 mr-2;
}

.oauth .github {
    @apply bg-[#24292F] focus:ring-[#24292F]/50 focus:ring-gray-500 hover:bg-[#050708]/30;
}

.oauth .google {
    @apply bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-[#4285F4]/60;
}

.oauth .vk {
    @apply bg-[#3b5998] hover:bg-[#3b5998]/90 focus:ring-[#3b5998]/60;
}

.oauth .mirea {
    @apply text-gray-900 bg-gray-100 hover:bg-gray-200 focus:ring-gray-500;
}

.footer {
    @apply flex items-center justify-between text-sm;
}

.footer .back {
    @apply flex flex-nowrap gap-1 align-middle items-center;
}
.footer .back svg {
    @apply w-3 h-3;
}

.footer a {
    @apply text-gray-400 hover:text-gray-500;
}

.noAccount {
    @apply text-sm font-light text-gray-400;
}

.noAccount a {
    @apply font-medium hover:underline;
}

.nextBackBtns {
    @apply grid grid-cols-2 gap-4;
}

.stepHide {
    @apply hidden;
}

.stepShow {
    @apply absolute z-50 opacity-100;
}