.cardContainer {
    @apply p-2
    w-full
    md:w-1/2
    xl:w-1/3;
}

.card {
    @apply cursor-pointer p-2 rounded-md flex flex-col py-2 transition-all duration-300
    ;
}

.title {
    @apply font-bold text-xl mb-2;
}

.content {

}