.changePasswordContainer {
    @apply flex flex-col gap-2;
}

.inputs {
    @apply flex flex-col
    gap-2 md:gap-4;
}

.oldPassword {
    @apply w-full pr-1 md:w-1/2;
}

.newPasswords {
    @apply gap-2 md:gap-4 grid
    grid-cols-1 md:grid-cols-2;
}