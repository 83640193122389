.mainContainer {
    @apply flex  items-center justify-center
    flex-col
    lg:flex-row lg:flex-wrap;
}

.dataBlock {
    @apply min-w-[20rem] p-6 rounded-md gap-x-3
    m-5
    md:m-10;
}

.title {
    @apply font-bold text-lg mb-2;
}

.inputs {

}

.buttons {
    @apply mt-5 my-2;
}

.tableBlock {
    @apply  p-6 rounded-md gap-x-3
    m-5
    md:m-10;
}

.table {
    @apply border-collapse w-full rounded-md;
}

.table thead tr th {
    @apply font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300
    p-2
    md:p-3;
}

.table tbody tr {
    @apply
    mb-10 bg-white
    lg:mb-0 lg:hover:bg-gray-100;
}

.table tbody tr td {
    @apply w-full w-auto text-gray-800 text-center border border-b
    p-1
    md:p-2;
}