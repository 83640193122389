.inviteContainer {
    @apply w-full h-full flex justify-center content-center items-center;
}

.title {
    @apply text-2xl flex justify-center;
}

.miniTitle {
    @apply text-slate-400 text-sm;
}

.description {
    @apply text-lg w-full;
}

.card {
    @apply max-w-lg h-auto py-5 sm:py-7 px-6 sm:px-10;
}

.groupBlock {
    @apply flex flex-col gap-2 sm:gap-4
    sm:flex-row sm:flex-wrap
    pt-3 sm:py-6 justify-between;
}

.miniBlock {
    @apply w-full sm:w-1/3 mb-2 sm:mb-0;
}

.descriptionBlock {
    @apply pb-4 sm:pb-6;
}