.pageContainer {
    @apply
    p-1
    w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 flex-grow;
}

.card {
    @apply p-2 w-full rounded-md cursor-pointer;
}

.cardActive {
    @apply bg-gray-200 bg-opacity-60 font-semibold text-black;
}

.colorBox {
    @apply rounded-md h-12 bg-white mt-1;
}

.darkness {
    @apply bg-main-bg;
}

.default {
    background: linear-gradient(99.96deg, #020611 9.99%, #0F1B38 100%);
    filter: brightness(1);
}

.purple {
    background: linear-gradient(120deg, #dd03e4, #5611ec);
    filter: brightness(0.25);
}

.grayCalorie {
    background: linear-gradient(120deg, #6E45E1, #89D4CF);
    filter: brightness(0.25);
}

.graySomeone {
    background: linear-gradient(120deg, #28313B, #485461);
    filter: brightness(0.4);
}

.graySurfboard {
    background: linear-gradient(120deg, #5E5C5C, #9DC5C3);
    filter: brightness(0.25);
}

.black {
    background: linear-gradient(120deg, #000000, #000000);
    filter: brightness(0.25);
}

.purple2 {
    background: linear-gradient(120deg, rgb(139, 10, 130) 6.9%, rgb(73, 6, 70) 73.2%);
}

.rainbowLine {
    background: linear-gradient(135deg, rgb(19, 55, 115), rgb(32, 7, 80),
    rgb(27, 88, 111), rgb(99, 19, 90), rgb(12, 51, 76));
}

.blackRed {
    background: linear-gradient(115deg, rgb(0, 0, 0) 5.8%, rgb(178, 14, 14) 112.6%);
    filter: brightness(0.8);
}

.rainbowLight {
    background: linear-gradient(96.2deg, rgb(255, 230, 112) 10.4%, rgb(255, 100, 100) 43.8%, rgb(0, 93, 219) 105.8%);
    filter: brightness(0.5);
}

.darkBlue {
    background: linear-gradient(178.1deg, rgb(60, 55, 106) 8.5%, rgb(23, 20, 69) 82.4%);
    filter: brightness(0.8);
}




