.icon {
    @apply text-gray-700 group-hover:text-gray-700 transition-colors duration-500 w-9;
}

.active {
    @apply text-gray-400;
}

.menuBox {
    @apply z-[99] fixed -bottom-1 w-screen;
}

.menu {
    @apply bg-neutral-800 shadow-lg rounded-t-2xl pb-4;
}

.menuList {
    @apply flex flex-row space-x-3 justify-evenly;
}

.item {
    @apply flex w-auto;
}

.link {
    @apply p-3 text-gray-400;
}

.linkBox {
    @apply flex flex-col items-center;
}

.text {
    @apply text-xs transition-all duration-200 hidden;
}

@media screen and (min-width: 500px) {
    .text {
        @apply block;
    }
}

.boll {
    @apply h-2 w-2 rounded-full group-hover:bg-yellow-500
    transition-all duration-150 delay-100;
}