.queue {
    /*glass-morphism style*/
    @apply flex-col transition duration-300
    justify-between align-middle content-center items-center rounded-md p-2 text-gray-200
    md:pl-7 md:pr-3 md:flex-row md:flex
    lg:h-auto;
    /*hover:bg-white hover:bg-opacity-20;*/
    /*background: rgba(255, 255, 255, 0.1);*/
    /*backdrop-filter: blur(10px);*/
    /*-webkit-backdrop-filter: blur(10px);*/
    /*border: none;*/
    /*outline: none;*/

}

.archive {
    @apply relative filter brightness-75;

}

.subject {
    @apply w-full text-base md:text-lg font-bold;
}

.queueStatus {
    @apply pr-3 content-center items-center justify-center hidden sm:static md:flex;
}

.bollActive {
    @apply relative flex h-4 w-4;
}

.bollActive span {
    @apply relative inline-flex h-3 w-3 rounded-full bg-green-500 opacity-80;
}

.bollActive span::after {
    content: '';
    @apply absolute inline-flex h-full w-full animate-ping rounded-full bg-green-400 opacity-75;
}

.bollInactive {
    @apply relative flex h-4 w-4;
}

.bollInactive span {
    @apply relative inline-flex h-3 w-3 rounded-full bg-orange-500 opacity-80;
}

.bollInactive span::after {
    content: '';
    @apply absolute inline-flex h-full w-full rounded-full bg-amber-400 opacity-75;
}

.bollClosed {
    @apply relative flex h-4 w-4;
}

.bollClosed span {
    @apply relative inline-flex h-3 w-3 rounded-full bg-red-500 opacity-80;
}

.bollClosed span::after {
    content: '';
    @apply absolute inline-flex h-full w-full  rounded-full bg-red-400 opacity-75;
}

.bollArchived {
    @apply relative flex h-4 w-4;
}

.bollArchived span {
    @apply relative inline-flex h-3 w-3 rounded-full bg-gray-500 opacity-80;
}

.bollArchived span::after {
    content: '';
    @apply absolute inline-flex h-full w-full rounded-full bg-gray-400 opacity-75;
}

.queueInfo {
    @apply grow w-full align-middle;
}

.queueBtn {
    @apply mt-3 flex py-2 h-14
    md:mt-0;
}

.queueInfoList {
    @apply mt-2 text-xs flex flex-wrap flex-col gap-1.5
    md:flex-row md:mt-0 md:gap-x-3 md:text-sm md:justify-between md:pr-4
    lg:justify-start lg:gap-x-5 lg:pr-0;
}

.listBlockTitle {
    @apply font-light text-sm;
}

.listBlockStatus {
    @apply text-base font-bold;
}