.memberPriority {
    @apply w-full flex items-center justify-center content-center
    col-span-1
    md:col-span-2
    lg:col-span-3 ;
}

.member {
    @apply w-full flex items-center justify-center content-center;
}

.cardContainerPriority {
    @apply rounded-md flex flex-col justify-center items-center gap-5
    w-full
    md:w-1/2
    lg:w-1/3
    xl:w-1/4;
}

.cardContainer {
    @apply rounded-md flex flex-col justify-center items-center gap-5
    w-full
    xl:w-3/4;
}

.cardImg {
    @apply rounded-t-md relative object-center w-full md:w-96 h-80 object-cover;
}

.cardContent {
    @apply flex flex-col justify-center items-center gap-1;
}

.cardName {
    @apply text-xl text-gray-200 font-bold pb-2 border-double border-b-2;
}

.cardSpecialities {
    @apply p-3 text-sm text-gray-400 font-normal;
}
