.card {
    @apply relative flex flex-col;
}

.content {
    @apply relative flex flex-row gap-2 mb-1.5 transition-all duration-[1000ms] ease-out;
}

.done {
    @apply opacity-0;
}

.badge {
    @apply cursor-pointer;
}

.icon {
    @apply w-6 h-6 hover:scale-105 focus:scale-95 transition-all duration-200 ease-in-out;
}

.doneIcon {
    @apply text-green-600;
}

.completeBadge {
    @apply relative w-6 h-6;
}

.completeBadge > * {
    @apply absolute transition-all;
}

/*check*/
.completeBadge > *:first-child {
    @apply text-green-600;
}

.completeBadge > *:last-child {
    @apply opacity-0 text-red-600;
}

.completeBadge:hover > *:first-child {
    @apply opacity-0;
}

.completeBadge:hover > *:last-child {
    @apply opacity-100;
}

.iconSmall {
    @apply w-4 h-4;
}

.groupBadge {
    @apply flex flex-row gap-2 items-center font-semibold;
}

.priority {
    @apply absolute top-2 right-2 flex flex-row-reverse gap-1.5 items-center;
}

.priority > span {
    @apply w-2 h-2 bg-gray-600 rounded-full;
}

.priorityHigh > span {
    @apply bg-red-600;
}

.priorityMedium > span {
    @apply bg-yellow-600;
}

.priorityLow > span {
    @apply bg-blue-600;
}


.footer {
    @apply text-sm mt-auto bottom-0 flex flex-row justify-between items-center -mx-3 -mb-2
    transition-all duration-[1000ms] ease-in-out;
}

.footer > * {
    @apply px-1.5 py-0.5 template__base grow;
}

/* not first-child */
.footer > *:not(:first-child) {
    @apply border-l border-opacity-30 border-gray-400;
}

.footer > *:first-child {
    @apply rounded-bl-md;
}

.footer > *:last-child {
    @apply rounded-br-md;
}

.link {
    @apply max-w-fit;
}

/*.card:before {*/
/*    content: '123';*/
/*    @apply top-0 left-0 absolute h-full w-full rounded-md z-10*/
/*    bg-gray-800 bg-opacity-50 flex flex-col justify-center items-center*/
/*    brightness-75;*/
/*}*/
.doneAnimation {
    @apply top-0 left-0 absolute h-full w-full rounded-md z-10
    bg-gray-800 bg-opacity-50 flex flex-col justify-center items-center;
}

.doneAnimationText {
    @apply cursor-pointer transition hover:underline;
}

