.activateContainer {
    @apply w-full h-full flex justify-center content-center items-center;
}

.card {
    @apply max-w-lg h-auto py-5 sm:py-7 px-6 sm:px-10;
}

.title {
    @apply text-2xl flex justify-center;
}

.text {
    @apply pt-3 sm:py-6;
}

.headerBts {
    @apply flex flex-wrap justify-end items-center gap-2 md:gap-4 w-full sm:w-auto;
}
