.label {
    @apply flex flex-row items-center;
}

.svg > * {
    @apply w-5 h-5 text-gray-500 mr-2;
}

.title {
    @apply font-semibold mr-1;
}

.value {
    @apply text-gray-300;
}