.groups {
    @apply flex flex-col;
}

.groupList {
    @apply flex flex-col gap-y-1 mt-2;
}

.groupCard {
    @apply px-2 p-0.5 flex flex-row justify-between content-center items-center;
}

.groupList > .groupCard {
    @apply pb-1;
    border-bottom: var(--main-border);
}

.groupList > .groupCard:last-child {
    @apply border-b-0 pb-0;
}


.moreButton {
    @apply flex justify-center content-center h-full;
}

.groupData {
    @apply flex flex-col;
}

.groupName {
    @apply font-semibold text-base;
}

.groupAlias {
    @apply text-xs italic;
}

.groupCounter {
    @apply flex flex-row content-end text-sm;
}

.noGroups {
    @apply flex flex-col items-center justify-center text-center text-gray-400;
}