.elementContainer {
    @apply w-full flex flex-col;
}

.inputComponent {
    @apply relative;
}

.copyBlock {
    @apply absolute bottom-0 right-0 flex items-center justify-end pr-2;
}

.copyIcon {
    @apply w-6 h-6 bg-gray-800 cursor-pointer p-1 mb-2 bg-opacity-80 hover:bg-opacity-100 rounded-md transition-all duration-300 ease-in-out
}