.badge {
    @apply text-white font-bold text-sm flex flex-col;
}

.title {
    @apply text-white font-bold text-sm px-1.5 w-full text-left;
}

.value {
    @apply px-1 p-0.5 rounded-md text-lg font-medium break-words;
}