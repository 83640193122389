.main {
    @apply rounded-md h-5 flex justify-center w-fit;
}

.main > * {
    @apply text-sm px-1 font-semibold transition;
}

.main a {
    @apply text-blue-400;
}

.green {
    @apply bg-green-500 bg-opacity-60 text-gray-200;
}

.blue {
    @apply bg-blue-600 bg-opacity-60 text-gray-100;
}

.blue a {
    @apply text-gray-100 hover:bg-blue-900 rounded-md;
}

.orange {
    @apply bg-orange-500 bg-opacity-60 text-gray-200;
}

.orange a {
    @apply text-gray-200 hover:bg-orange-900 rounded-md;
}

.red {
    @apply bg-red-500 bg-opacity-60 text-gray-200;
}

.red a {
    @apply text-gray-200 hover:bg-red-900 rounded-md;
}