.content {
    @apply container mx-auto py-4 flex flex-col gap-2 md:gap-4;
}

.title {
    @apply flex flex-nowrap gap-2 items-center text-2xl font-semibold;
}

.icon {
    @apply w-6 h-6;
}

.input {

}

.header {
 @apply flex flex-nowrap gap-2 items-center justify-between;
}

.menuLogo {
    @apply flex flex-col items-center align-baseline content-end flex-shrink-0 text-white mr-6;
}

.menuLogo h3 {
    @apply font-bold text-2xl tracking-tight;
}

.menuLogo h5 {
    @apply ml-20 font-semibold tracking-tight text-gray-400 text-sm;
}

.menuLogo img {
    @apply h-10 w-10 -m-3;
}