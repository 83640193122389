.info {
    @apply mb-2
    md:mb-4;
}

.infoHeader {
    @apply flex content-center align-middle
    flex-col sm:flex-row
    sm:justify-between sm:items-center
    ;
}

.infoHeaderButtons {
    @apply flex flex-row-reverse sm:flex-nowrap gap-2 mt-2 sm:mt-0;
}

.upButton {
    @apply rounded-md p-1.5 text-sm;
}

.discipline {
    @apply text-lg md:text-xl font-bold;
}

.name {

}

.params {
    @apply flex flex-wrap
    justify-between md:justify-start
    gap-4 md:gap-8
    mt-2 md:mt-4;
}

.tableContainer {

}

.table {
    @apply border-collapse table-auto w-full text-sm;
}

.table th, .table td {
    @apply text-center content-center my-1;
}

.table .numHeader {
    @apply w-8;
}

.table .number {
    @apply w-8 h-8 flex justify-center items-center
    border-2 border-green-800 rounded-full;
}

.tableHeader {
    @apply text-gray-100 border-b border-gray-500;
}

.onlyDesktopRow {
    @apply hidden md:table-cell;
}

.onlyMobileRow {
    @apply table-cell md:hidden;
}

.connectedTimeContainer {
    @apply flex flex-col items-center;
}

.connectedTime {
    @apply rounded-md px-1;
}

.actionsContainer {
    @apply flex flex-wrap items-center content-center justify-center gap-x-1 font-bold;
}

.actionsContainer div {
    @apply cursor-pointer;
}

.actionDelete {
    /*Красное стекло*/
    @apply text-xs rounded-md p-1 hover:bg-red-500 hover:bg-opacity-60;
}

.actionUp {
    @apply text-xs rounded-md p-1 hover:bg-green-400 hover:bg-opacity-60;
}

.actionsContainer div svg {
    @apply w-4 h-4;
}

.mainModalBlock {
    @apply text-center p-5 md:p-10
}

.modalButtonBlock {
    @apply pt-10 justify-between
}

.buttonAgree {
    @apply text-white bg-red-700 border border-transparent hover:bg-red-800 focus:ring-red-300 disabled:hover:bg-red-800 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-500 dark:disabled:hover:bg-red-600 focus:ring-2 flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg py-1 px-2
}

.buttonDisAgree {
    @apply bg-white border border-gray-200 hover:bg-gray-100 focus:text-blue-700 dark:bg-transparent dark:text-white dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 focus:ring-2 flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg py-1 px-2
}


.content {
    @apply flex flex-col-reverse xl:flex-row gap-2 md:gap-4;
}

.list {
    @apply w-full;
}

.list:first-child {
    @apply w-full xl:w-7/12;
}

.list:nth-child(2) {
    @apply w-full xl:w-5/12;
}

.offers {
    @apply flex flex-col gap-2 md:gap-4;
}

.spin {
    @apply animate-spin;
}

.manageButtons {
    @apply grid grid-cols-2 mt-2 gap-2;
    /*@apply flex flex-wrap gap-2 mt-2;*/
}

.manageButtons > button {
    @apply flex-grow;
}

.manageButtons > .double {
    /*@apply col-span-2;*/
}