.content {
    @apply flex w-full
    flex-col-reverse
    md:flex-row
    gap-2 md:gap-4
    max-h-max;
}

.mainInfo {
    @apply md:basis-2/3 overflow-scroll;
}

.mainInfo::-webkit-scrollbar {
    width: 0;
}

.extraInfo {
    @apply md:basis-1/3 flex flex-col gap-2 md:gap-4;
}

.form {
    @apply flex flex-col gap-2 md:gap-4;
}

.groupTypeInfo {
    @apply flex flex-row gap-8;
}

.wrapperClass {
    @apply h-full;
}

.editorClass {
    @apply px-2 border border-t-0 border-gray-500 rounded-b -mt-1;
}

.toolbarClass {
    @apply rounded-b-none text-black;
    background: rgba(255, 255, 255, 0.15);
}

.toolbarClass > * {
    @apply rounded-none;
}

/*.code-view {*/
/*    color: #000;*/
/*}*/
/*.text-area {*/
/*    width: 100%;*/
/*    background: black;*/
/*    border: 1px solid #ccc;*/
/*    color: white;*/
/*    min-height: 200px;*/
/*}*/
