.header {
    @apply rounded-md w-full;
}

.imgContainer img {
    @apply h-28 rounded-md w-full object-cover;
}

.headerData {
    @apply flex md:flex-row md:items-center h-20 md:h-16 relative;
}

.avatar {
    @apply h-32 w-32 -mt-24 p-2 rounded-md absolute
    mx-2 md:mx-4;
}

.avatar img {
    @apply rounded-md;
}

.headerName {
    @apply text-gray-300 text-xl font-bold w-full text-left
    px-2 md:px-4
    md:pl-40;
}

.headerMobileData {
    @apply w-full flex md:flex-row md:items-center justify-between
    mt-10 md:mt-0;
}

.headerButton {
    @apply
    hidden md:block
    mx-2 md:mx-4;
}

.content {
    @apply w-full flex
    mt-2 md:mt-4
    gap-2 md:gap-4
    flex-col md:flex-row;
}

.staticInfo {
    @apply w-full h-fit md:w-1/3
}

.barContainer {
    @apply w-full md:w-2/3
}

.box {
    @apply h-28 w-full ;
    @apply relative;
    /*width: 50%;*/
    /*height: 50%;*/
}

.box:before {
    position: absolute;
    content: '';
    width: calc(100% + 30px);
    height: calc(100% + 30px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(.7);
    /*filter: blur(0);*/
    opacity: 1;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
    filter: blur(20px);
}

/*.box:hover:before {*/
/*    transform: translate(-50%, -50%) scale(1);*/
/*    filter: blur(50px);*/
/*}*/
.boxInner {
    position: relative;
    width: 100%;
    height: 100%;
}

.box:before,
.boxInner {
    @apply rounded-t-md bg-[#0073c7];
    background: rgb(15, 15, 31);
    /*background-color: rgb(1, 10, 56);*/
    /*box-shadow: 2px 2px 12px 0 rgba(0,0,0,0.58);*/
    /*border-radius: 21px;*/

    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='1600' height='198'%3e%3cdefs%3e%3clinearGradient id='a' x1='50%25' x2='50%25' y1='-10.959%25' y2='100%25'%3e%3cstop stop-color='%23003f6c' stop-opacity='.25' offset='0%25'/%3e%3cstop stop-color='%23005189' offset='50%25'/%3e%3cstop stop-color='%230063a6' offset='75%25'/%3e%3cstop stop-color='%230073c7' offset='100%25'/%3e%3c/linearGradient%3e%3c/defs%3e%3cpath fill='url(%23a)' fill-rule='evenodd' d='M.005 121C311 121 409.898-.25 811 0c400 0 500 121 789 121v77H0s.005-48 .005-77z'/%3e%3c/svg%3e"),
    url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='1600' height='198'%3e%3cdefs%3e%3clinearGradient id='a' x1='50%25' x2='50%25' y1='-10.959%25' y2='100%25'%3e%3cstop stop-color='%230063a6' stop-opacity='.25' offset='0%25'/%3e%3cstop stop-color='%230073c7' offset='50%25'/%3e%3cstop stop-color='%230084e9' offset='75%25'/%3e%3cstop stop-color='%23009bff' offset='100%25'/%3e%3c/linearGradient%3e%3c/defs%3e%3cpath fill='url(%23a)' fill-rule='evenodd' d='M.005 121C311 121 409.898-.25 811 0c400 0 500 121 789 121v77H0s.005-48 .005-77z'/%3e%3c/svg%3e"),
    url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='1600' height='198'%3e%3cdefs%3e%3clinearGradient id='a' x1='50%25' x2='50%25' y1='-10.959%25' y2='100%25'%3e%3cstop stop-color='%230073c7' stop-opacity='.25' offset='0%25'/%3e%3cstop stop-color='%230084e9' offset='50%25'/%3e%3cstop stop-color='%23009bff' offset='75%25'/%3e%3cstop stop-color='%2300a9ff' offset='100%25'/%3e%3c/linearGradient%3e%3c/defs%3e%3cpath fill='url(%23a)' fill-rule='evenodd' d='M.005 121C311 121 409.898-.25 811 0c400 0 500 121 789 121v77H0s.005-48 .005-77z'/%3e%3c/svg%3e");

    background-repeat: repeat-x;
    background-size: 1600px 50%;
    background-position: 0 130%, -50px 130%, 500px 130%;

    animation: 20s waves linear infinite forwards;
}

@keyframes waves {
    to {
        background-position: 1600px 130%, 3150px 130%, 5300px 130%;
    }
}