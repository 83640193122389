.pageContainer {
    @apply min-h-screen container mx-auto py-12
    px-10
    sm:px-20
    lg:px-40 ;
}

.pageContent {
    @apply flex flex-col justify-center items-center;
}

.title {
    @apply font-bold
    text-3xl mb-6
    md:text-4xl md:mb-10
    lg:text-5xl;
}

.content {
    @apply w-full flex flex-wrap items-stretch ;
}