.table {
    @apply w-full border-separate border-spacing-y-1;
}

.table > thead {
    @apply text-xs text-gray-500 uppercase;
}

.table > tbody {
    @apply text-sm;
}

.table > tbody > tr {
    @apply py-4;
}

.table > thead > tr > th {
    @apply py-2 text-left whitespace-nowrap;
}

.id {
    @apply flex items-center justify-center;
}

.avatar {
    @apply w-10 h-10 rounded-md;
}

.actions {
    @apply flex flex-nowrap gap-2 w-full;
}

.trClass {
    background: rgba(255, 255, 255, 0.08);
    @apply transition duration-300 rounded-md;
}

.trClass:hover {
    background: rgba(255, 255, 255, 0.3);
    @apply transition duration-300 rounded-md;
}

.trClass td:first-child {
    @apply rounded-l-md;
}

.trClass td:last-child {
    @apply rounded-r-md;
}