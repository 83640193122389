.content {
    @apply overflow-x-auto;
}

.header {
    @apply flex justify-between items-center;
}

.title {
    @apply font-bold text-lg;
}

.table {
    @apply w-full table-auto border-separate border-spacing-y-1 mt-2;
}

.table > tbody > tr > td {
    @apply pb-1;
    border-bottom: var(--main-border);
}

.table > tbody > tr:last-child > td {
    @apply border-b-0 pb-0;
}


.btnBlock {
    @apply flex justify-center items-center;
}

.btn {
    @apply flex justify-center items-center p-1 rounded-full cursor-pointer;
}

.btnIcon {
    @apply w-4 h-4 rounded-full;
}