.filterContainer {
    @apply text-white flex flex-wrap justify-between items-center gap-2
    mb-2 md:mb-4;
}

.filterContainer .title {
    @apply flex flex-nowrap gap-4;
}

.queuesContainer {
    @apply flex flex-col w-full
    gap-y-2 mt-2
    lg:gap-y-4 lg:mt-4;
}

.headerBts {
    @apply flex flex-wrap justify-end items-center gap-2 md:gap-4 w-full sm:w-auto;
}

.spin {
    @apply animate-spin;
}

.filter {
    @apply flex flex-nowrap justify-items-center rounded-md;
    border: var(--main-border);
}

.reload {
    @apply cursor-pointer flex h-full items-center p-2.5 hover:bg-opacity-50 hover:bg-gray-800 transition-all duration-200 ease-in-out rounded-md;
    border: var(--main-border);
}

.filter .btn {
    @apply cursor-pointer flex h-full items-center p-2 hover:bg-opacity-50 hover:bg-gray-800 transition-all duration-200 ease-in-out;
}

.filter .btn div {
    @apply flex items-center justify-center;
}


.filter .btn:first-child {
    @apply rounded-l-md;
}

.filter .btn:last-child {
    @apply rounded-r-md;
}


.bollActive {
    @apply relative flex h-4 w-4;
}

.bollActive span {
    @apply relative inline-flex h-3 w-3 rounded-full bg-green-500 opacity-80;
}

.bollActive span::after {
    content: '';
    @apply absolute inline-flex h-full w-full  rounded-full bg-green-400 opacity-50;
}

.bollInactive {
    @apply relative flex h-4 w-4;
}

.bollInactive span {
    @apply relative inline-flex h-3 w-3 rounded-full bg-orange-500 opacity-80;
}

.bollInactive span::after {
    content: '';
    @apply absolute inline-flex h-full w-full rounded-full bg-amber-400 opacity-75;
}

.bollClosed {
    @apply relative flex h-4 w-4;
}

.bollClosed span {
    @apply relative inline-flex h-3 w-3 rounded-full bg-red-500 opacity-80;
}

.bollClosed span::after {
    content: '';
    @apply absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75;
}

.bollArchived {
    @apply relative flex h-4 w-4;
}

.bollArchived span {
    @apply relative inline-flex h-3 w-3 rounded-full bg-gray-500 opacity-80;
}

.bollArchived span::after {
    content: '';
    @apply absolute inline-flex h-full w-full rounded-full bg-gray-400 opacity-50;
}

.active span::after {
    @apply scale-150;
}