.info {
    @apply mb-2
    md:mb-4;
}

.content {
    @apply w-full flex flex-col-reverse md:flex-row gap-2 md:gap-4;
}

.list {
    @apply w-full md:w-7/12 h-fit;
}

.infoBlock {
    @apply w-full md:w-5/12 flex flex-col gap-2 md:gap-4;
}

.mainInfoContainer {
    @apply flex flex-col;
}

.mainInfo {
    @apply w-full
    flex flex-col md:flex-row md:flex-wrap
    gap-4 md:gap-8
    mt-2 md:mt-4;
}

.actions {
    @apply flex flex-col
    gap-1 md:gap-2
    mt-2 md:mt-4;
}

.listContainer {
    @apply mt-2 flex flex-col gap-1;
}

.count {
    @apply text-sm ml-2 text-gray-500;
}

.emptyList {
    @apply mt-2 md:mt-4
}
