.currentGroupCard {
    @apply mb-2 md:mb-4;
}

.title {
    @apply text-2xl font-semibold;
}

.miniGeneralBlock {
    @apply flex
    flex-wrap md:flex-row
    mt-4 mb-4 md:mb-0
    gap-x-4 md:gap-x-16 lg:gap-x-24
    gap-y-2 md:gap-y-4;
}

.generalBlock {
    @apply flex justify-between flex-col md:flex-row;
}

.groupBlock {
    @apply w-5/6 md:w-4/5;
}

.buttonBlock {
    @apply flex flex-col justify-end gap-4 w-full sm:w-auto;
}

