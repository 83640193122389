.currentGroupContainer {
    @apply flex flex-wrap justify-between items-center gap-2;
}
.groupsContainer {
    @apply flex flex-col w-full
    gap-y-2 mt-2
    lg:gap-y-4 md:mt-4;
}

.groupsBlock {
    @apply mt-2 md:mt-4;
}

.title{
    @apply text-2xl font-semibold pt-1 pb-3;
}

.groupsList {
    @apply flex flex-col gap-y-1;
}