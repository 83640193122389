.pageContainer {
    @apply p-2 px-3 rounded-md text-gray-300;
}

.title {
    @apply text-2xl font-bold px-3 mt-2 w-full border-b border-gray-400;
}

.content {

}