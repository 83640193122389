:root {
    --background-color-from: #dd03e4;
    --background-color-to: #5611ec;
    --background-btightness: 0.25;
    --background-direction: 120deg;
    --background-gradient: linear-gradient(
            var(--background-direction),
            var(--background-color-from),
            var(--background-color-to)
    );
}


html[data-theme="default"] {
    --background-gradient: linear-gradient(99.96deg, #020611 9.99%, #0F1B38 100%);
    --background-btightness: 1;
}

html[data-theme="darkness"] {
    /*--background-gradient: linear-gradient(99.96deg, #020611 9.99%, #0F1B38 100%);*/
    /*--background-btightness: 1;*/

    --background-color-from: #1f1e24;
    --background-color-to: #1f1e24;
    --background-btightness: 1;
}

html[data-theme="purple"] {
    --background-btightness: 0.25;
    --background-color-from: #dd03e4;
    --background-color-to: #5611ec;
}

html[data-theme="grayCalorie"] {
    --background-color-from: #6E45E1;
    --background-color-to: #89D4CF;
}

html[data-theme="graySomeone"] {
    --background-color-from: #28313B;
    --background-color-to: #485461;
}

html[data-theme="graySurfboard"] {
    --background-color-from: #5E5C5C;
    --background-color-to: #9DC5C3;
}

html[data-theme="black"] {
    --background-color-from: #000000;
    --background-color-to: #000000;
}

html[data-theme="purple2"] {
    --background-color-from: rgb(139, 10, 130) 6.9%;
    --background-color-to: rgb(73, 6, 70) 73.2%;
    --background-btightness: 1;
}

html[data-theme="rainbowLine"] {
    --background-gradient: linear-gradient(135deg, rgb(19, 55, 115), rgb(32, 7, 80),
    rgb(27, 88, 111), rgb(99, 19, 90), rgb(12, 51, 76));
    --background-btightness: 1;
}

html[data-theme="rainbowLight"] {
    --background-gradient: linear-gradient(96.2deg, rgb(255, 230, 112) 10.4%, rgb(255, 100, 100) 43.8%, rgb(0, 93, 219) 105.8%);
    --background-btightness: 0.4;
}

html[data-theme="blackRed"] {
    --background-gradient: linear-gradient(115deg, rgb(0, 0, 0) 5.8%, rgb(178, 14, 14) 112.6%);
    --background-btightness: 0.8;
}

html[data-theme="darkBlue"] {
    --background-gradient: linear-gradient(178.1deg, rgb(60, 55, 106) 8.5%, rgb(23, 20, 69) 82.4%);
    --background-btightness: 0.8;

}

.pageContainer {
    @apply w-full overflow-hidden;
    height: 100dvh;
}

.pageContainer:after {
    @apply w-full top-0 left-0 absolute h-full -z-[1] transition duration-75;
    content: '';
    filter: brightness(var(--background-btightness));
    /*background: linear-gradient(var(--background-direction), var(--background-color-from), var(--background-color-to));*/
    background: var(--background-gradient);
}

.page {
    @apply flex items-start justify-between;
}

@media screen and (max-width: 1024px) {
    .leftMenuContainer {
        @apply left-4 z-50 py-4 shadow-lg w-64 transition-all duration-300
        transform translate-x-[-130%]
        absolute
        lg:block lg:relative lg:translate-x-0
        ;
        height: calc(100dvh - 4rem);
    }

    .leftMenuContainerOpen {
        @apply left-4 z-50 h-screen py-4 shadow-lg w-64 transition-all duration-300
        absolute
        lg:block lg:relative lg:translate-x-0
        transform translate-x-0;
        height: calc(100dvh - 4rem);
    }

    .leftMenu {
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px);
    }
}

@media screen and (width: 1024px) {
    .leftMenuContainer,
    .leftMenuContainerOpen {
        @apply left-0;
    }
}


@media screen and (min-width: 1024px) {
    .leftMenuContainer {
        @apply right-0 z-50 py-4 ml-5 shadow-lg w-64
        block relative
        ;
        height: 100dvh;
    }

    .leftMenuContainerOpen {
        @apply right-4 z-50 py-4 ml-5 shadow-lg w-64
        block relative
        ;
        height: 100dvh;

    }

    .leftMenuContainer,
    .leftMenuContainerOpen {
        @apply left-0;
    }
}

.leftMenu {
    @apply rounded-md
    pt-16
    lg:pt-0;
    height: calc(100dvh - 2rem);
    border: var(--main-border);
    @apply md:h-full;
}

.leftMenuTitle {
    @apply hidden text-2xl font-extrabold flex-col items-center justify-center pt-6 text-slate-200
    lg:flex;
}

.linkToLk {
    @apply flex lg:hidden;
}

.avatarInLeftMenu {
    @apply px-4 py-2;
}

.avatarInLeftMenu > a > div {
    @apply w-full text-white;
}

.leftMenu nav {
    @apply mt-2 p-2;
}

.leftMenu nav ul {
    @apply mb-2;
}

.leftMenuContent {
    @apply mt-5;
}

.leftMenuGroup {
    @apply px-4 pb-6;
}

.leftMenuGroup h2 {
    @apply mb-2 text-xs font-medium uppercase text-slate-400;
}

.leftMenuGroup ul {
    @apply text-sm font-medium;
}

.leftMenuGroup ul a {
    @apply flex rounded-md text-white text-sm bg-transparent my-1 p-2 px-5 w-full transition duration-300 border-0 bg-gray-700;
    /*background: rgba(255, 255, 255, 0.19);*/
    /*box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);*/
    /*border: var(--main-border);*/
    background: rgba(255, 255, 255, 0.08);
}


.leftMenuGroup ul a:hover {
    background: rgba(255, 255, 255, 0.2);
    /*box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);*/
}

.leftMenuGroup button {
    @apply text-center flex rounded-md text-white text-sm bg-transparent my-1 p-2 px-5 w-full lg:hidden;
    background: rgba(180, 28, 28, 0.19);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.rightBlock {
    @apply flex flex-col w-full p-2 space-y-2 md:p-4 md:space-y-4;
}

.rightBlockHeader {
    @apply hidden lg:block w-full shadow-lg items-center h-16 rounded-md z-40 text-slate-200;
    border: var(--main-border);
}

.rightBlockHeaderContent {
    @apply relative z-20 flex h-full px-3 mx-auto justify-between items-center;
}

.rightBlockHeaderMobile {
    @apply block lg:hidden w-full shadow-lg items-center h-16 rounded-md z-[100] text-slate-200;
    border: var(--main-border);

}

.headerTitle {
    @apply z-50 text-2xl font-bold flex content-center justify-center items-center;
}

.headerUserCard {
    @apply text-lg rounded-md p-1 pr-2 flex flex-nowrap items-center gap-x-2 relative h-10 cursor-pointer font-bold;
}

.headerUserCard img {
    @apply h-full w-auto rounded-md;
}

.content {
    @apply relative overflow-auto text-gray-200 pb-[7rem];
    height: calc(100lvh - 4rem);
    /*@apply md:h-full;*/
}

/* total width */
.content::-webkit-scrollbar {
    display: none;
    @apply -mr-6;
    position: fixed;
    background-color: rgba(255, 255, 255, 0);
    width: 6px;
}

/* background of the scrollbar except button or resizer */
.content::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0);
}

/* set button(top and bottom of the scrollbar) */
.content::-webkit-scrollbar-button {
    display: none;
}

.content::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0);
    border-radius: 16px;
    /*border:5px solid rgba(255, 255, 255, 0);*/
}

.content:hover::-webkit-scrollbar-thumb {
    background-color: #a0a0a5;
}

/*.content::-webkit-scrollbar-thumb:hover {*/
/*    background-color:#a0a0a5;*/
/*    border:4px solid #f4f4f4*/
/*}*/

.activeGroup a {
    @apply font-bold flex flex-nowrap flex-row  w-full items-start
}

.iconContainer {

}

@media screen and (max-width: 375px) {
    .headerTitle {
        @apply text-xl;
    }
}