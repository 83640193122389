.numberContainer {
    @apply
    pr-2;
}

.mainContainer {
    @apply flex flex-row align-middle items-center h-auto md:h-[50px];
}

.number {
    @apply border-2 border-gray-400 rounded-md w-8 h-8 flex items-center justify-center;
}

.userInfo {
    @apply flex items-center whitespace-nowrap text-white;
}

.userInfo .avatar {
    @apply w-10 h-10 rounded-full ml-2;
    @apply hidden md:block;
}


.userInfo .info {
    @apply pl-3;
}

.userInfo .info .name {
    @apply text-base font-bold;
}

.userInfo .info .role {
    @apply font-normal filter brightness-[0.6];
}

.btnBlock {
    @apply px-2 md:px-2 p-1 box-border flex items-center justify-end whitespace-nowrap text-white;
}