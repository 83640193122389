.menuContainer {
    @apply w-full z-10 md:py-3 relative md:container md:mx-auto;


}

.menuBlock {
    @apply m-2 text-white z-50 relative flex px-10 flex-wrap items-center justify-between rounded-lg py-4;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    /* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); */
}

.menuLogo {
    @apply flex items-center align-baseline content-end flex-shrink-0 text-white mr-6;
}

.menuLogo h3 {
    @apply font-bold text-2xl tracking-tight;
}

.menuLogo h5 {
    @apply ml-20 font-semibold tracking-tight text-gray-400 text-sm;
}

.menuLogo img {
    @apply h-10 w-10 -m-3;
}

.menuDesktop {
    @apply w-0 hidden w-full md:flex md:w-auto items-center gap-5 bottom-0;
}

.menuDesktop li {
    @apply list-none;
}

.menuDesktop .desktopLink {
    text-decoration: none;
    @apply cursor-pointer text-center flex justify-center text-gray-50 px-3 py-1.5 rounded-lg;
    transition: background 0.6s;
}

.menuDesktop .desktopLink:hover {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(10px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.desktopHighlight {
    @apply flex flex-wrap gap-x-2;
}

.desktopHighlight a {
    text-decoration: none;
    @apply cursor-pointer text-center flex justify-center text-gray-50 px-3 py-1.5 rounded-lg
    transition duration-300;
    border: 2px #2c356b solid;
}

.desktopHighlight a:hover {
    background-color: #2c356b;
}

.glassBlock {
    @apply rounded-lg;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(3px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.menuMobile {
    @apply flex justify-center items-center md:hidden;
}

.menuMobileBg {
    /*@apply backdrop-blur-3xl top-24 left-0 flex w-full h-screen absolute justify-center bg-black opacity-60 transition-all z-10;*/
    @apply opacity-100 transition duration-500 backdrop-blur-2xl  fixed z-10;
    height: 200vh;

}


.menuMobileContainer {
    @apply block w-5 transform -translate-x-1/2 -translate-y-1/2 ;
    flex-direction: column;
}

.menuMobileList {
    @apply transition  duration-500 flex absolute top-24 justify-center w-full h-screen z-20;
}

.menuMobileNav {
    @apply bg-gray-900 rounded-md bg-opacity-95 mx-4  w-full font-semibold text-lg -mt-5 py-5 pb-4 h-fit;
    list-style-type: none;
    /*background: rgba(255, 255, 255, 0.2);*/
    /*backdrop-filter: blur(20px);*/
    /*-webkit-backdrop-filter: blur(20px);*/
}


.menuMobileNav li {
    @apply px-2 py-1 flex w-full box-border text-white;
    /*@apply p-2 bg-gray-600 bg-opacity-70 rounded-md w-full;*/
    /*background: rgba(255, 255, 255, 0.2);*/
    /*backdrop-filter: blur(20px);*/
    /*-webkit-backdrop-filter: blur(20px);*/
    /*@apply  p-4 border-b-2;*/
}

.mobileLink {
    @apply p-2 bg-gray-600 bg-opacity-70 rounded-md w-full flex justify-center;
    /*background: rgba(255, 255, 255, 0.2);*/
    /*backdrop-filter: blur(20px);*/
    /*-webkit-backdrop-filter: blur(20px);*/
    /*@apply  p-4 border-b-2;*/
}

/*.menuMobileList {*/
/*    @apply flex flex-wrap gap-3 list-none absolute top-24 left-0 w-full h-full items-center justify-evenly bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg backdrop-saturate-150 rounded-lg;*/
/*}*/

.menuMobileBgHidden {
    @apply opacity-0;
}

.menuMobileListHidden {
    @apply hidden;
}

.menuMobileNavHidden {
    @apply hidden;
}
.mobileCabinet a {
    @apply w-full;
}
.loginsMobile {
    @apply w-full;
}

.loginsMobile a {
    @apply w-1/2;
}

.loginsMobile a:first-child {
    @apply pr-2;
}
/*.mobileCabinet a {*/
/*    @apply bg-blue-600 p-2 bg-opacity-80 rounded-md w-full flex justify-center;*/
/*}*/