.content {

}

.title {
    @apply text-sm text-slate-400;
}

.value {
    @apply text-white text-base font-semibold;
}

.link {
    @apply cursor-pointer underline;
}


.loader {
    @apply w-20 h-5 animate-pulse;
}