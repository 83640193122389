.pageContainer {
    @apply container mx-auto py-12
    px-10
    sm:px-20
    lg:px-40 ;
}

.titleContainer {
    @apply text-center pb-12;
}

.upTitle {
    @apply text-base font-bold text-gray-600;
}

.title {
    @apply font-bold
    text-3xl
    md:text-4xl
    lg:text-5xl;
}

.teamContainer {
    @apply w-full grid flex items-center justify-center content-center gap-10
    grid-cols-1
    md:grid-cols-2
    lg:grid-cols-3;
}
