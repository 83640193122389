.groupGeneralBlock {
    @apply flex flex-wrap gap-x-32 gap-y-2;
}

.mainBlock {
    @apply flex flex-col md:flex-row justify-between;
}

.buttonBlock {
    @apply flex flex-col md:flex-row gap-2 md:items-center;
}

