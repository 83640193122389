.status {
    @apply text-xs font-semibold rounded-md px-0.5 text-white bg-opacity-60;
}

.active {
    @apply bg-green-600 bg-opacity-70;
}

.waiting {
    @apply bg-yellow-600 bg-opacity-70;
}

.banned {
    @apply bg-red-600 bg-opacity-70;
}

.deleted {
    @apply bg-rose-600 bg-opacity-70;
}