.container {
    /*@apply grid grid-cols-1 py-2*/
    /*md:grid-cols-2;*/
}

.imgBlock {
    @apply flex flex-col md:flex-row p-2 m-2 border border-gray-600 rounded-md;
}

.imgBlock .imgContainer {
    @apply p-4 flex flex-row px-0 h-fit;
    /*grid-cols-1*/
    /*lg:px-0 lg:grid-cols-3;*/

}

.avatarImg {
    @apply p-2 lg:pl-0 flex items-center justify-center;
}

.imgButtons {
    @apply flex flex-col gap-y-2;
}

.imgBlock img {
    @apply w-full col-span-1 object-cover pl-0;
    border-radius: 1rem;
}

.credirionals {
    @apply w-full px-2 md:px-5 grid grid-cols-1 md:grid-cols-2 gap-2 justify-between ;
}

/*.credirionals > div {*/
/*    @apply w-1/4*/
/*}*/

/*.creditional*/

.lastImgBlock {
    @apply col-span-2 flex flex-col content-center items-center;
}

.lastImgBlockTitle {
    @apply text-sm md:text-base font-bold px-5;
}

.lastImgBlockImages {
    @apply hidden mb-2
    lg:grid grid-cols-1
    md:grid-cols-2
    lg:grid-cols-3
    gap-2;
}

.lastImgBlockImages img {
    @apply w-full h-full object-cover p-2 border;
    border-radius: 12px;
}

.infoBlock {
    @apply p-2 m-2 border border-gray-600 rounded-md;
}

.buttonsContainer {
    @apply px-2 mb-2 flex flex-row-reverse;
}

.buttonsContainer .btnContainer {
    @apply w-1/5;
}
