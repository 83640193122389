.footerContainer {
    @apply w-full z-10 text-gray-300 p-2 px-3 rounded-md
    mb-2 relative
    md:py-3 md:container md:mx-auto md:mb-4;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    /* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); */
}

.footerContent {
    @apply relative flex px-10 flex flex-wrap items-center justify-between rounded-lg;
    z-index: 1;
}

.footerRight {
    @apply w-full  flex flex-col items-center justify-center
    md:w-auto;
}

.footerRight {
    @apply w-full text-center flex flex-col items-center justify-center
    md:w-auto;
}