.BG {
    background: linear-gradient(99.96deg, #020611 9.99%, #0F1B38 100%);
    @apply w-full min-h-screen pt-2 md:pt-4;
}

.headerUserCard {
    @apply text-lg rounded-md p-1 pr-2 flex flex-nowrap items-center gap-x-2 relative h-10 cursor-pointer font-bold justify-end;
}

.bigTitle {
    @apply text-gray-200 text-2xl;
}

.upperCard {
    @apply flex flex-row justify-between p-4 m-2 md:my-0 md:mx-4 gap-2;
}

.groupsContainer {
    @apply grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 p-2 md:p-4 pt-0;
}

.userCard {
    @apply text-gray-200;
}