.headerUserCard {
    @apply text-lg rounded-md p-1 pr-2 flex flex-nowrap items-center gap-x-2 relative h-11 cursor-pointer font-bold;
}

.headerUserCard img {
    @apply h-full w-auto rounded-md;
}

.userData {
    @apply flex flex-col text-left;
}

.name {
    @apply text-sm lg:text-base;
}

.username {
    @apply text-xs text-gray-400 lg:text-sm;
}

.menuItem {
    @apply font-bold rounded-md p-1 px-2 cursor-pointer text-gray-300;
}

.logout {
    @apply cursor-pointer text-red-500 text-opacity-80 transition-all duration-300;
}

.menuBlock {
    @apply absolute right-44 rounded-md p-1 flex gap-4;
}