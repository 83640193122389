.language {
    @apply mt-2 md:mt-4 flex flex-col
     gap-2;
}

.langContainer {
    @apply flex flex-wrap gap-2;
}

.langBtn {
    @apply flex gap-4 justify-start w-full;
}