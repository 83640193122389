.content {
    @apply flex flex-col gap-2;
}

.banned {
    @apply text-red-500;
}

.active {
    @apply text-green-500;
}