.btn {
    @apply flex justify-center content-center h-fit rounded-md font-semibold shadow-md transition duration-300 text-gray-200 text-base px-8 py-2 hover:shadow-lg;
    border: none;
    outline: none;
    @apply hover:bg-opacity-80;
}


.btnSm {
    @apply px-4 py-1 text-sm;
}

.btnMd {
    @apply px-4 py-1 text-base;
}

.btnLg {
    @apply px-12 py-2 text-lg;
}

.btnRed {
    @apply bg-red-600 bg-opacity-90;
}

.btnGray {
    /*@apply bg-opacity-30;*/
    /*background: rgba(255, 255, 255, 0.2);*/
    @apply bg-gray-700;
}

.btnBlue {
    /*@apply bg-sky-600 bg-opacity-30 hover:bg-opacity-75;*/
    @apply bg-blue-500 bg-opacity-90;
}

.btnGreen {
    /*@apply bg-emerald-600 bg-opacity-30 hover:bg-opacity-75;*/
    @apply bg-emerald-600;
}

.btnOrange {
    @apply bg-orange-500;
}

.btnDark {
    @apply bg-black bg-opacity-30 hover:bg-opacity-75;
}

.btnOutline {
    @apply ring-1 ring-inset;
}

.btnOutlineGray {
    @apply ring-gray-700 hover:bg-gray-700;
    /*@apply bg-red-500;*/
}

.btnOutlineBlue {
    @apply ring-blue-800 hover:bg-blue-700;
}

.btnOutlineRed {
    @apply ring-red-800 hover:bg-red-700;
}

.btnOutlineOrange {
    @apply ring-orange-500 hover:bg-orange-700;
}

.btnOutlineGreen {
    @apply ring-green-700 hover:bg-green-800;
}

.btnOutlineDark {
    @apply ring-black hover:bg-black;
}

.wightFull {
    @apply w-full;
}

.wightFit {
    @apply w-fit;
}

.wightAuto {
    @apply w-auto;
}

.wightSmFull {
    @apply w-full sm:w-auto;
}

.btnIcon {
    @apply p-1;
}

.disabled {
    @apply opacity-50 cursor-default;
}