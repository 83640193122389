/*noinspection CssUnknownTarget*/
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #1f1e24;
}

.BG {
    @apply w-full min-h-screen text-gray-300 overflow-hidden;
    background: linear-gradient(99.96deg, #020611 9.99%, #0F1B38 100%);
}

:root {
    --main-border: 1px solid #434248;
}


@layer base {
    .template__base {
        /*@apply border border-black;*/
        /*backdrop-filter: blur(16px);*/
        /*-webkit-backdrop-filter: blur(16px);*/
        /*background: rgba(255, 255, 255, 0.1);*/
        /*background: rgba(146, 82, 230, 0.27);*/
        /*background: rgb(0, 0, 0);*/

        /*@apply border border-gray-800 shadow-2xl;*/
        /*@apply bg-[#1C1C1E];*/
        /*@apply bg-main-card;*/
        border: var(--main-border)
    }

    .template__hovered {
        transition: background-color 0.2s ease-in-out;
    }

    .template__hovered:hover {
        /*background: rgba(111, 2, 255, 0.27);*/
        background: rgba(255, 255, 255, 0.2);
    }


    .template__light__base {

        /*backdrop-filter: blur(.25rem);*/
        /*-webkit-backdrop-filter: blur(.25rem);*/
        /*background: rgba(255, 255, 255, 0.1);*/


        /*background: hsl(0, 0, 100, 0.45);*/
        /*background: rgba(146, 82, 230, 0.27);*/
        /*background: rgb(0, 0, 0);*/
    }

    .template__card {
        @apply p-2 px-3 rounded-md;
        /*@apply bg-main-card;*/
        /*border: 1px solid #434248;*/
        /*@apply rounded-none shadow-2xl text-white*/
    }

    .activeBadge,
    .soonBadge,
    .testBadge {
        @apply relative;
    }

    .activeBadge:before {
        content: 'Текущая';
        @apply absolute top-0 right-0 bg-green-600 text-white text-xs px-2 py-1 rounded-bl-md rounded-tr-md z-10;
    }


    .soonBadge:before {
        content: 'Скоро';
        @apply absolute top-0 right-0 bg-yellow-600 text-white text-xs px-2 py-1 rounded-bl-md rounded-tr-md z-10;
    }

    .testBadge:before {
        content: 'Тестирование';
        @apply absolute top-0 right-0 bg-yellow-300 text-yellow-900 text-xs px-2 py-1 rounded-bl-md rounded-tr-md z-10;
    }


    /*.glass-50 {*/
    /*    background: rgba(255, 255, 255, 0.1);*/
    /*    backdrop-filter: blur(1px);*/
    /*    -webkit-backdrop-filter: blur(1px);*/
    /*    !* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); *!*/
    /*}*/
    /*.glass-100 {*/
    /*    background: rgba(255, 255, 255, 0.1);*/
    /*    backdrop-filter: blur(3px);*/
    /*    -webkit-backdrop-filter: blur(3px);*/
    /*    !* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); *!*/
    /*}*/
    /*.glass-200 {*/
    /*    background: rgba(255, 255, 255, 0.1);*/
    /*    backdrop-filter: blur(6px);*/
    /*    -webkit-backdrop-filter: blur(6px);*/
    /*    !* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); *!*/
    /*}*/
    /*.glass-300 {*/
    /*    background: rgba(255, 255, 255, 0.1);*/
    /*    backdrop-filter: blur(10px);*/
    /*    -webkit-backdrop-filter: blur(10px);*/
    /*    !* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); *!*/
    /*}*/
    /*.glass-400 {*/
    /*    background: rgba(255, 255, 255, 0.1);*/
    /*    backdrop-filter: blur(20px);*/
    /*    -webkit-backdrop-filter: blur(20px);*/
    /*    !* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); *!*/
    /*}*/
    /*.glass-block-50 {*/
    /*    @apply glass-50 p-2 px-3 rounded-md;*/
    /*}*/
    /*.glass-block-100 {*/
    /*    @apply glass-100 p-2 px-3 rounded-md;*/
    /*}*/
    /*.glass-block-200 {*/
    /*    @apply glass-200 p-2 px-3 rounded-md;*/
    /*}*/
    /*.glass-block-300 {*/
    /*    @apply glass-300 p-2 px-3 rounded-md;*/
    /*}*/
    /*.glass-block-400 {*/
    /*    @apply glass-400 p-2 px-3 rounded-md;*/
    /*}*/
    /*.glass-red-50 {*/
    /*    background: rgba(255, 0, 0, 0.25);*/
    /*    backdrop-filter: blur(1px);*/
    /*    -webkit-backdrop-filter: blur(1px);*/
    /*    !* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); *!*/
    /*}*/
    /*.glass-red-100 {*/
    /*    background: rgba(255, 0, 0, 0.25);*/
    /*    backdrop-filter: blur(3px);*/
    /*    -webkit-backdrop-filter: blur(3px);*/
    /*    !* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); *!*/
    /*}*/
    /*.glass-red-200 {*/
    /*    background: rgba(255, 0, 0, 0.25);*/
    /*    backdrop-filter: blur(4px);*/
    /*    -webkit-backdrop-filter: blur(6px);*/
    /*    !* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); *!*/
    /*}*/
    /*.glass-red-300 {*/
    /*    background: rgba(255, 0, 0, 0.25);*/
    /*    backdrop-filter: blur(10px);*/
    /*    -webkit-backdrop-filter: blur(10px);*/
    /*    !* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); *!*/
    /*}*/
    /*.glass-red-400 {*/
    /*    background: rgba(255, 0, 0, 0.25);*/
    /*    backdrop-filter: blur(20px);*/
    /*    -webkit-backdrop-filter: blur(20px);*/
    /*    !* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); *!*/
    /*}*/
    /*.glass-green-50 {*/
    /*    background: rgba(0, 255, 97, 0.25);*/
    /*    backdrop-filter: blur(1px);*/
    /*    -webkit-backdrop-filter: blur(1px);*/
    /*    !* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); *!*/
    /*}*/
    /*.glass-green-100 {*/
    /*    background: rgba(0, 255, 97, 0.25);*/
    /*    backdrop-filter: blur(3px);*/
    /*    -webkit-backdrop-filter: blur(3px);*/
    /*    !* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); *!*/
    /*}*/
    /*.glass-green-200 {*/
    /*    background: rgba(0, 255, 97, 0.25);*/
    /*    backdrop-filter: blur(4px);*/
    /*    -webkit-backdrop-filter: blur(6px);*/
    /*    !* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); *!*/
    /*}*/
    /*.glass-green-300 {*/
    /*    background: rgba(0, 255, 97, 0.25);*/
    /*    backdrop-filter: blur(10px);*/
    /*    -webkit-backdrop-filter: blur(10px);*/
    /*    !* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); *!*/
    /*}*/
    /*.glass-green-400 {*/
    /*    background: rgba(0, 255, 97, 0.25);*/
    /*    backdrop-filter: blur(20px);*/
    /*    -webkit-backdrop-filter: blur(20px);*/
    /*    !* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); *!*/
    /*}*/
}

/* Input */

.glass-input {
    @apply text-white font-bold text-sm;
}

.glass-input span {
    @apply text-white font-bold text-sm px-1.5;
}

.glass-input input {
    @apply rounded-md text-white bg-transparent border-2 border-white p-2;
    /*input in glassmorphism style*/
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    border: none;
    outline: none;
    padding: 0.5rem 1rem;
    color: white;
    font-size: 1rem;
    font-weight: 500;
    width: 100%;
}

.glass-input input:focus {
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.glass-input div {
    @apply px-1.5 text-xs text-[#ff7070] mb-2;
}


/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*}*/
