.content {
    @apply grid
    grid-cols-1 md:grid-cols-2
    gap-2 md:gap-4;
}

.systemInfo {
    @apply mt-2 flex flex-col
    gap-2 md:gap-4;
}

.actions {
    @apply flex flex-col mt-2
    gap-2 md:gap-4
    ;
}

.btns {
    @apply grid grid-cols-2 gap-2 mt-2;
}

.dangerZone {
    @apply bg-red-500 bg-opacity-20
}