.mainContainer {
    @apply w-full
    px-2 rounded-md h-auto md:h-[50px];
    background: hsl(0, 0, 100, 0.45);
    backdrop-filter: blur(.25em);
}


.numberContainer {
    @apply
    pl-2
    lg:pl-4;
}

.number {
    @apply border-2 border-gray-400 rounded-md w-8 h-8 flex items-center justify-center;
}

.userInfo {
    @apply flex items-center whitespace-nowrap text-white;
}

.userInfo .avatar {
    @apply w-10 h-10 rounded-full ml-2;
    /*@apply hidden md:block;*/
}


.userInfo .info {
    @apply pl-3;
}

.userInfo .info .name {
    @apply text-base font-bold;
}

.userInfo .info .role {
    @apply font-normal filter brightness-[0.6];
}

.btnBlock {
    @apply gap-2 px-2 md:px-2 p-1 box-border flex items-center justify-end whitespace-nowrap text-white;
}

.movingUpNumber {
    @apply border-emerald-500;
}

.movingDownNumber {
    @apply border-red-600 border-opacity-60;
}

.confirmText {
    @apply text-sm flex items-center text-center justify-center h-full p-1;
}