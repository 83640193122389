.pageContainer {
    @apply flex flex-col gap-2;
}

.title {
    @apply text-xl font-bold;
}

.block {
    @apply flex flex-col ;
}

.subtitle {
    @apply text-lg font-bold;
}

.text {
    @apply text-base;
}

.button {
    @apply flex flex-row items-center gap-2;
}