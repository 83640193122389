.content {
    @apply flex flex-col gap-2;
}

.title {
    @apply flex flex-nowrap justify-between;
}

.icon {
    @apply w-6 h-6 cursor-pointer text-gray-500 duration-200 transform
    hover:text-white hover:scale-105;
}

.iconOk {
    @apply text-green-500;
}

.attrList {
    @apply flex flex-col gap-2;
}

.actions {
    @apply flex flex-nowrap gap-1;
}

.editButtons {
    @apply grid grid-cols-4 gap-2;
}