.cardContainer {
    @apply flex flex-col justify-between w-full p-4;
}

.info {
    @apply flex flex-col gap-2 text-gray-200;
}

.title {
    @apply text-gray-200 text-center text-xl;
}

.groupContainer {
    @apply flex gap-x-28 pt-2;
}