.dialog {
    @apply w-full h-full fixed top-0 left-0 flex z-[100];
}

.dialogBG {
    @apply fixed inset-0 backdrop-blur-sm backdrop-brightness-50 transition-opacity;
}

.dialogContainer {
    @apply fixed inset-0 z-10 overflow-y-auto;
}

.dialogContent {
    @apply flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0;
}

.panel {
    @apply w-full bg-white bg-opacity-[.07] shadow-2xl backdrop-blur-2xl border-2 border-black border-opacity-10 relative transform overflow-hidden rounded-lg text-left transition-all sm:my-8 sm:w-full sm:max-w-lg;
}

.mainContent {
    @apply px-4 pt-5 pb-4 sm:p-6 sm:pb-4;
}

.mainContentContainer {
    @apply sm:flex sm:items-start;
}

.iconContainer {
    @apply mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-500 bg-opacity-20 sm:mx-0 sm:h-10 sm:w-10;
}

.icon {
    @apply h-6 w-6 text-red-600;
}

.header {
    @apply text-lg font-semibold leading-6 text-gray-50;
}

.body {
    @apply mt-2 text-sm text-gray-100;
}

.btnBlock {
    @apply px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 gap-3;
}

.btnBlock button:nth-child(n+2) {
    @apply mt-3 sm:mt-0 inline-flex;
}

.bgEnter {
    @apply ease-out duration-300;
}

.bgEnterFrom {
    @apply opacity-0;
}

.bgEnterTo {
    @apply opacity-100
}

.bgLeave {
    @apply ease-in duration-200;
}

.bgLeaveFrom {
    @apply opacity-100;
}

.bgLeaveTo {
    @apply opacity-0;
}

/*Окно смены в очереди*/

.swapPanel {
    @apply sm:max-w-sm;
}

.swapContent {
    @apply flex flex-col w-full;
}

.swapHeader {
    @apply w-full text-center text-xl text-white font-semibold mb-6;
}

.swapUsers {
    @apply flex flex-col gap-3 mx-auto font-extrabold;
}


.swapFirstUser,
.swapSecondUser {
    @apply flex items-center drop-shadow-2xl text-lg font-semibold;
}


.swapNumber {
    @apply mr-4 border-2 border-gray-400 rounded-md w-8 h-8 flex items-center justify-center;
    /*@apply mr-4 border-2 border-gray-400 rounded-md w-6 h-6 flex items-center justify-center;*/
}

.swapFirstUser .swapNumber {
    @apply border-emerald-500;
}

.swapSecondUser .swapNumber {
    @apply border-red-600 border-opacity-60;
}

.swapIcon {
    @apply w-8 h-8 text-white;
}

.swapUserName {
}

.createGroupPanel {
    /*@apply*/
}

.createGroupTitle {
    @apply text-xl;
}


.inputBlock {
    @apply w-full grid gap-y-4 h-full;
}

.infoBlock {
    @apply w-full text-gray-100 pt-10 sm:w-1/2 sm:pl-10 sm:pt-0;
}

.tittleInfo {
    @apply text-lg font-bold pb-0.5 sm:text-sm;
}

.info {
    @apply font-normal;
    font-size: 10px;
}

.warning {
    @apply text-red-500
}

.moreInfo {
    @apply text-blue-500
}

/* createQueueBlock */

.createQueueBlock {
    @apply w-full grid gap-y-4 h-full;
}

.fieldset {
    @apply flex flex-col gap-4 border-gray-500 rounded-md
    p-2 sm:p-4;
    border: var(--main-border);
}

.miniInfo {
    @apply flex
}

/* createLinkModal */

.inviteLinkBtns {
    @apply flex flex-col gap-2 md:flex-row md:gap-4;
}

/* chanelLanguegeModal */

.langContainer {
    @apply flex flex-col gap-2;
}

.langBtn {
    @apply flex gap-4 justify-start w-full;
}