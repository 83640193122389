.lesson {
    @apply w-full relative flex flex-nowrap gap-2 items-center	;
}
.miniLesson {
    /*@apply ;*/
}

.number {
    @apply pr-2 border-r text-sm h-full flex flex-col justify-center content-center items-center	;
}

.room {
    @apply absolute bottom-0 right-0 text-xs rounded-br-md rounded-tl-md px-1 py-0.5;
}

.room2 {
    @apply bottom-5 rounded-br-none border-b border-gray-600
}


.name {
    @apply text-sm;
}

.teacher {
    @apply text-xs;
}

.somethingLessons {
    @apply flex flex-col gap-2;
}

.lessonTypeLecture {
    /*@apply bg-blue-500 bg-opacity-60;*/
    /*@apply bg-rose-600 bg-opacity-70;*/
    /*@apply bg-violet-600 bg-opacity-60;*/
    /*@apply bg-[#0e7490] bg-opacity-70;*/
    @apply bg-violet-700 bg-opacity-90;
}

.lessonTypePractice {
    /*@apply bg-orange-500 bg-opacity-60;*/
    /*@apply bg-purple-500 bg-opacity-50;*/
    /*@apply bg-rose-600 bg-opacity-70;*/
    /*@apply bg-teal-600 bg-opacity-70;*/
    /*@apply bg-[#3730a3] bg-opacity-75;*/
    @apply bg-blue-700 bg-opacity-90;
}

.lessonTypeLab {
    /*@apply bg-green-500 bg-opacity-60;*/
    /*@apply bg-[#15803d] bg-opacity-70;*/
    @apply bg-cyan-700;}

