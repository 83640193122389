.BG {
    background: linear-gradient(99.96deg, #020611 9.99%, #0F1B38 100%);
    @apply w-full min-h-screen;
}
.content {
    @apply h-screen text-gray-200 flex flex-col items-center justify-center content-center;
}

.modal {
    @apply max-w-md flex flex-col gap-3 p-4;
}

.hiddenText {
    @apply text-gray-400 text-sm flex gap-2 w-full flex-nowrap justify-between;
}

.sendAgain {
    @apply transition-all hover:text-blue-500 cursor-pointer;
}

.exit {
    @apply transition-all hover:text-red-500 cursor-pointer;
}
