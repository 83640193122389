.bgComponent {
    @apply w-full min-h-screen p-10;
    background: linear-gradient(99.96deg, #020611 9.99%, #0F1B38 100%)
}

.block {
    @apply flex flex-wrap gap-5 content-center justify-center w-full;
}

.buttons {
    @apply flex flex-wrap p-2 md:p-4 gap-5 justify-center;
}

.componentsContainer {
    @apply container mx-auto flex flex-wrap gap-10;
}

.inputs {
    @apply place-content-center container mx-auto;
}