.inputBlock {
    @apply text-white font-bold text-sm flex flex-col;
}

.inputBlockTittle {
    @apply text-white font-bold text-sm px-1.5 w-full text-left;
}

.inputBlockTittleDisabled {
    @apply text-gray-500;
}

.inputBlockTittle small {
    @apply text-xs text-gray-400;
}

.input {
    @apply w-full font-medium text-base px-3 py-2 border-none outline-none rounded-md text-white bg-transparent border-white p-2;
    /*input in glassmorphism style*/
    /*background: rgba(255, 255, 255, 0.1);*/
    backdrop-filter: blur(10px);
    /*-webkit-backdrop-filter: blur(10px);*/
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    border: var(--main-border);
}

.input option {
    @apply text-black;
}

.input:disabled {
    @apply opacity-50;
}

.input:disabled * .inputBlockTittle {
    @apply text-red-600;
}

.inputBlock div {
    /*@apply px-1.5 text-xs text-[#ff7070] mb-2 text-left;*/
}