.content {
    @apply flex w-full
    flex-col-reverse
    md:flex-row
    gap-2 md:gap-4
    max-h-max;
}

.mainInfo {
    @apply md:basis-2/3 overflow-scroll;
}

.mainInfo::-webkit-scrollbar {
    width: 0;
}

.extraInfo {
    @apply md:basis-1/3 flex flex-col gap-2 md:gap-4;
}

.parameters {
    @apply flex flex-col gap-2 md:gap-4;
}

.groupTypeInfo {
    @apply flex flex-row gap-8;
}

.extraInfoBlock {
    @apply flex flex-wrap gap-4 gap-x-12;
}

.hr {
    @apply w-full border-t border-gray-500;
}

.actionBtns {
    @apply flex flex-wrap gap-2 md:gap-4;
}


.actionBtns > *:not(:first-child) {
    @apply flex-1;
}