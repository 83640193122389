.card {
    @apply flex flex-col gap-1;
}

.hr {
    @apply border-t border-main-border;
}

.info {
    @apply flex flex-row justify-between items-center;
}

.numberContainer {
    @apply
    pl-2
    lg:pl-4;
}

.number {
    @apply border-2 border-gray-400 rounded-md w-8 h-8 flex items-center justify-center;
}

.smNumber {
    @apply border border-gray-400 w-5 rounded-md flex items-center justify-center;
    /*// Пропорционально уменьшаем размеры*/
    /*width: 1.5rem;*/
    /*height: 1.5rem;*/

}

.movingUpNumber {
    @apply border-emerald-500;
}

.movingDownNumber {
    @apply border-red-600 border-opacity-60;
}

.mute {
    @apply text-gray-400;
}

.arrow {
    @apply w-4;
}