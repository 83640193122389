.card {
    @apply p-4 flex flex-col gap-2;
}

.title {
    @apply mt-2 -mb-1;
}

.title h2 {
    @apply text-xs font-medium uppercase text-slate-400;
}

.attrList {
    @apply flex flex-col gap-y-1;
}
